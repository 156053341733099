import React from 'react'
import Styled from 'styled-components'
import "../../assets/css/style.css";
import premium from '../../assets/icons/verified.svg'
import customised from '../../assets/icons/customized.svg'
import time from '../../assets/icons/time.svg'
import factory from '../../assets/icons/factory.svg'
import techsupport from '../../assets/icons/techsupport.svg'
import environment from '../../assets/icons/environment.svg'
import diversity from '../../assets/icons/diversity.svg'
import handshake from '../../assets/icons/handshake.svg'
import Slider from 'react-slick';

export default function Service() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        // rows: 1,
        // slidesPerRow: 1,
        centerPadding: "60px",
        className: "center",
    };

    return (
        <Section id='service'>
            <SectionWrapper className='wrapper'>
                <ServicesectionTop>
                    <ServiceTittle>services</ServiceTittle>
                    <ServiceSubTittle>At Mplus Concrete, we simplify your construction journey with top-notch ready mix concrete services, making your building process straightforward, strong, and stress-free.</ServiceSubTittle>
                </ServicesectionTop>
                <ServiceUl>
                    <ServiceItems>
                        <IconContainer><Icon src={premium} /></IconContainer>
                        <Tittle>Premium Quality Concrete</Tittle>
                        <ContentBox>
                            <Content>Elevating your projects with strength, precision, and excellence. Our ready-mix concrete service sets the standard for uncompromising quality and durability</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={customised} /></IconContainer>
                        <Tittle>Customized Mix Designs</Tittle>
                        <ContentBox>
                            <Content>We understand that each project is unique, and our ready-mix concrete service is designed to meet your specific requirements. Our team of experts collaborates with you to create mixes that perfectly align with your project's needs, ensuring optimal performance, durability, and precision.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={time} /></IconContainer>
                        <Tittle>Timely Delivery</Tittle>
                        <ContentBox>
                            <Content>Experience the reliability of our Ready-Mix Concrete service, where timely delivery is as crucial as the concrete itself. With a well-organized logistics system and a commitment to efficiency, we ensure that your concrete arrives exactly when and where you need it.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={factory} /></IconContainer>
                        <Tittle>State-of-the-Art Batching Plants</Tittle>
                        <ContentBox>
                            <Content>Our cutting-edge facilities are equipped with the latest technology, ensuring precision, efficiency, and consistency in every mix. With a commitment to excellence, our batching plants stand as a testament to our dedication to delivering top-quality ready-mix concrete</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={techsupport} /></IconContainer>
                        <Tittle>Expert Technical Support</Tittle>
                        <ContentBox>
                            <Content>Our commitment to environmentally friendly practices set us apart in the ready-mix concrete industry. From eco-conscious sourcing to sustainable production, we prioritize the planet in every mix we deliver.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={environment} /></IconContainer>
                        <Tittle>Environmentally Friendly Practices</Tittle>
                        <ContentBox>
                            <Content>Our commitment to environmentally friendly practices set us apart in the ready-mix concrete industry. From eco-conscious sourcing to sustainable production, we prioritize the planet in every mix we deliver.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={factory} /></IconContainer>
                        <Tittle>Competitive Pricing</Tittle>
                        <ContentBox>
                            <Content>Our commitment to delivering cost-effective solutions ensures that your construction projects benefit from both quality and affordability. Your success is our priority, and we deliver on it with competitive pricing at the core of our service.</Content>
                        </ContentBox>
                    </ServiceItems><ServiceItems>
                        <IconContainer><Icon src={diversity} /></IconContainer>
                        <Tittle>Stringent Quality Control</Tittle>
                        <ContentBox>
                            <Content>Our commitment to delivering cost-effective solutions ensures that your construction projects benefit from both quality and affordability. Your success is our priority, and we deliver on it with competitive pricing at the core of our service.</Content>
                        </ContentBox>
                    </ServiceItems><ServiceItems>
                        <IconContainer><Icon src={handshake} /></IconContainer>
                        <Tittle>Reliable Supply Chain</Tittle>
                        <ContentBox>
                            <Content>Our commitment to timely deliveries is reinforced by a well-organized and trustworthy supply chain, ensuring that your construction projects remain on schedule. Our company values reliability, offering you the assurance that your ready-mix concrete will be delivered consistently and seamlessly.</Content>
                        </ContentBox>
                    </ServiceItems>
                </ServiceUl>

                {/* Mobile View Service */}
                <MobileViewService>
                    <Slider {...settings}>
                <ServiceItems>
                        <IconContainer><Icon src={premium} /></IconContainer>
                        <Tittle>Premium Quality Concrete</Tittle>
                        <ContentBox>
                            <Content>Elevating your projects with strength, precision, and excellence. Our ready-mix concrete service sets the standard for uncompromising quality and durability</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={customised} /></IconContainer>
                        <Tittle>Customized Mix Designs</Tittle>
                        <ContentBox>
                            <Content>We understand that each project is unique, and our ready-mix concrete service is designed to meet your specific requirements. Our team of experts collaborates with you to create mixes that perfectly align with your project's needs, ensuring optimal performance, durability, and precision.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={time} /></IconContainer>
                        <Tittle>Timely Delivery</Tittle>
                        <ContentBox>
                            <Content>Experience the reliability of our Ready-Mix Concrete service, where timely delivery is as crucial as the concrete itself. With a well-organized logistics system and a commitment to efficiency, we ensure that your concrete arrives exactly when and where you need it.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={factory} /></IconContainer>
                        <Tittle>State-of-the-Art Batching Plants</Tittle>
                        <ContentBox>
                            <Content>Our cutting-edge facilities are equipped with the latest technology, ensuring precision, efficiency, and consistency in every mix. With a commitment to excellence, our batching plants stand as a testament to our dedication to delivering top-quality ready-mix concrete</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={techsupport} /></IconContainer>
                        <Tittle>Expert Technical Support</Tittle>
                        <ContentBox>
                            <Content>Our commitment to environmentally friendly practices set us apart in the ready-mix concrete industry. From eco-conscious sourcing to sustainable production, we prioritize the planet in every mix we deliver.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={environment} /></IconContainer>
                        <Tittle>Environmentally Friendly Practices</Tittle>
                        <ContentBox>
                            <Content>Our commitment to environmentally friendly practices set us apart in the ready-mix concrete industry. From eco-conscious sourcing to sustainable production, we prioritize the planet in every mix we deliver.</Content>
                        </ContentBox>
                    </ServiceItems>
                    <ServiceItems>
                        <IconContainer><Icon src={factory} /></IconContainer>
                        <Tittle>Competitive Pricing</Tittle>
                        <ContentBox>
                            <Content>Our commitment to delivering cost-effective solutions ensures that your construction projects benefit from both quality and affordability. Your success is our priority, and we deliver on it with competitive pricing at the core of our service.</Content>
                        </ContentBox>
                    </ServiceItems><ServiceItems>
                        <IconContainer><Icon src={diversity} /></IconContainer>
                        <Tittle>Stringent Quality Control</Tittle>
                        <ContentBox>
                            <Content>Our commitment to delivering cost-effective solutions ensures that your construction projects benefit from both quality and affordability. Your success is our priority, and we deliver on it with competitive pricing at the core of our service.</Content>
                        </ContentBox>
                    </ServiceItems><ServiceItems>
                        <IconContainer><Icon src={handshake} /></IconContainer>
                        <Tittle>Reliable Supply Chain</Tittle>
                        <ContentBox>
                            <Content>Our commitment to timely deliveries is reinforced by a well-organized and trustworthy supply chain, ensuring that your construction projects remain on schedule. Our company values reliability, offering you the assurance that your ready-mix concrete will be delivered consistently and seamlessly.</Content>
                        </ContentBox>
                    </ServiceItems>
                    </Slider>
                </MobileViewService>

            </SectionWrapper>
        </Section>
    )
}
const Section = Styled.section`
    padding: 20px 0 80px;
    @media (max-width: 640px) {
    padding: 20px 0 40px;
        
    }
`
const SectionWrapper = Styled.div``
const ServicesectionTop = Styled.div``
const ServiceTittle = Styled.h2`
    color: #BA181A;
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media (max-width: 768px) {
        font-size: 36px;
    }
    @media (max-width: 640px) {
        font-size: 30px;
    }
    @media (max-width: 480px) {
        font-size: 26px;
    }
`
const ServiceSubTittle = Styled.p`
    margin-top: 30px;
    color: #282828;
    text-align: justify;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    width: 80%;
    @media (max-width: 640px) {
        width: 100%;
        font-size: 16px;
    }
    @media (max-width: 480px) {
    margin-top: 20px;
    }
`

const ServiceUl = Styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 60px;
    @media (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
        display: none;
    }
`
const MobileViewService = Styled.div`
    display: none;
    margin-top: 40px;
    @media (max-width: 768px) {
        display: block;
    }
`
const ServiceItems = Styled.li`
    background: #BA181A;
    min-height: 380px;
    padding: 40px;
    @media (max-width: 480px) {
    min-height: 440px;
        
    }
`
const IconContainer = Styled.div`
    width:44px;
    height: 44px;
    padding: 6px;
    background: #FFF;
    margin: 0 auto;
    border-radius: 4px;
`
const Icon = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
`
const Tittle = Styled.h3`
    color: #FFF;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 30px;
    text-align: center;
    height: 30px;
    @media (max-width: 480px) {
    font-size: 16px;
    }
`
const ContentBox = Styled.div`
    margin-top: 38px;
    @media (max-width: 480px) {
    margin-top: 10px;
        
    }
`
const Content = Styled.p`
    color: #E4E4E4;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.7rem;
`