import React from 'react'
import Styled from 'styled-components'
import "../../assets/css/style.css";
import operation from '../../assets/images/operation.png'
import operation2 from '../../assets/images/operation2.png'




export default function Operation() {
    return (
        <Section id='operation'>
            <SectionWrapper className='wrapper' >
                <OperationTop>
                    <Heading>mode of operation</Heading>
                    <Subheading>At Mplus Concrete, our mode of operation is focused on efficiency and precision, we ensure that each batch of ready-mix concrete is carefully crafted and promptly delivered, guaranteeing a hassle-free construction experience for our clients.</Subheading>
                </OperationTop>
                <ItemContainer>
                    <LeftItem>
                        <LeftUl>
                            <Li>
                                <Left><RedDot></RedDot></Left>
                                <Right>
                                    <TopTittle>Raw Material</TopTittle>
                                    <Content>Obtain high-quality raw materials, including cement, aggregates, water, and admixtures, from trusted suppliers. Conduct quality checks to ensure materials meet specified standards.</Content>
                                </Right>
                            </Li>
                            <Li>
                                <Left><RedDot></RedDot></Left>
                                <Right>
                                    <TopTittle>Batching and Mixing</TopTittle>
                                    <Content>Develop mix designs based on project specifications. Use computerized batching systems to precisely measure and combine materials.</Content>
                                </Right>
                            </Li>
                            <Li>
                                <Left><RedDot></RedDot></Left>
                                <Right>
                                    <TopTittle>Quality Control</TopTittle>
                                    <Content>Implement an accurate quality control process at every stage, from raw materials to the final product. Conduct tests to ensure the concrete meets strength, durability, and consistency standards.</Content>
                                </Right>
                            </Li>
                            <Li>
                                <Left><RedDot></RedDot></Left>
                                <Right>
                                    <TopTittle>Transportation</TopTittle>
                                    <Content>Optimize transportation routes and schedules for efficient concrete delivery.</Content>
                                </Right>
                            </Li>
                            <Li>
                                <Left><RedDot></RedDot></Left>
                                <Right>
                                    <TopTittle>Delivery</TopTittle>
                                    <Content>Ensure timely and reliable delivery of ready-mix concrete to construction sites.</Content>
                                </Right>
                            </Li>
                            <Li>
                                <Left><RedDot></RedDot></Left>
                                <Right>
                                    <TopTittle>Environmental Responsibility</TopTittle>
                                    <Content>Implement eco-friendly practices in concrete production and bond to environmental regulations.</Content>
                                </Right>
                            </Li>
                            <Li>
                                <Left><RedDot></RedDot></Left>
                                <Right>
                                    <TopTittle>Customer Interaction</TopTittle>
                                    <Content>Maintain open communication with customers, addressing queries and providing updates.</Content>
                                </Right>
                            </Li>
                        </LeftUl>
                    </LeftItem>
                    <RightItem>
                        <ImgContainer>
                            <Img src={operation} />
                            <MobileImage src={operation2} />
                        </ImgContainer>
                    </RightItem>
                </ItemContainer>
            </SectionWrapper>
        </Section>
    )
}
const Section = Styled.section`
    padding: 20px 0 50px;
`
const SectionWrapper = Styled.div``
const OperationTop = Styled.div``
const Heading = Styled.h4`
    color: #BA181A;
    font-size: 40px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media all and (max-width: 768px) {
        font-size: 34px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`
const Subheading = Styled.p`
    color: #282828;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    margin-top: 20px;
    width: 76%;
    @media all and (max-width: 640px) {
        width: 100%;
        font-size: 16px;
    }
 
`
const ItemContainer = Styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 2fr;
    grid-gap: 80px;
    @media all and (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        grid-gap: 6px;

    }
`
const LeftItem = Styled.div`
     @media (max-width: 768px) {
        width: 100%;
    }
`
const RightItem = Styled.div`
   
`
const LeftUl = Styled.ul`
    margin-top: 60px;
`
const Li = Styled.li`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
`
const Left = Styled.div`
   
`
const RedDot = Styled.span`
    width: 12px;
    height: 12px;
    background: #BA181A;
    display: inline-block;
    margin-top: 4px;
`
const Right = Styled.div`
    margin-left: 30px;
`
const ImgContainer = Styled.div`
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
    }
`
const Img = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    @media (max-width: 480px) {
        display: none;
    }
`
const MobileImage = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
    @media (max-width: 480px) {
        display: block;
        
    }

`
const TopTittle = Styled.h5`
    color: #292929;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    @media all and (max-width: 640px) {
    font-size: 16px;
        
    }
`
const Content = Styled.p`
    color: #282828;
    text-align: justify;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 20px;
    text-align: justify;
       @media all and (max-width: 768px) {
    font-size: 14px;
        
    }
`



