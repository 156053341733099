import React from 'react'
import styled from 'styled-components'
import Accordion from '../includes/AccordianFaq';


export default function Faq() {
  return (
    <Section id='faq'>
        <SectionWrapper className='wrapper' >
            <TopContainer>
              <Tittle>
                Frequently Asked Questions
              </Tittle>
            </TopContainer>
            <ItemContainer>
              <Accordion />
            </ItemContainer>
        </SectionWrapper>
    </Section>
  )
}

const Section = styled.div`
  background: #F4F4F4;
  padding: 60px 0 ;
`;
const SectionWrapper = styled.div``;
const TopContainer = styled.div``;
const Tittle = styled.h5`
  color: #BA181A;
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  @media all and (max-width: 640px) {
    font-size: 26px;
  }
    @media all and (max-width: 480px) {
    font-size: 22px;
  }

`;

const ItemContainer = styled.div`
  margin-top: 60px;
  @media all and (max-width: 640px) {
  margin-top: 40px;
    
  }
`;
