import React from 'react'
import Styled from 'styled-components'
import "../../assets/css/style.css";
import gallery from '../../assets/images/gallery1.png'
import gallery1 from '../../assets/images/gallery2.png'
import gallery2 from '../../assets/images/gallery3.png'
import gallery3 from '../../assets/images/gallery4.png'
import gallery4 from '../../assets/images/gallery 51.jpeg'
import gallery5 from '../../assets/images/gallery 52.jpeg'
import gallery6 from '../../assets/images/gallery 53.jpeg'
import gallery7 from '../../assets/images/gallery54.png'
import gallery8 from '../../assets/images/mplus  (1).jpg'
import gallery9 from '../../assets/images/mplus  (2).jpg'
import gallery10 from '../../assets/images/mplus  (3).jpg'
import gallery11 from '../../assets/images/mplus  (4).jpg'
import gallery12 from '../../assets/images/mplus  (5).jpg'


import video from '../../assets/images/video/video (1).mp4'
import video2 from '../../assets/images/video/video (2).mp4'
import video3 from '../../assets/images/video/video (3).mp4'







export default function Gallery() {
    return (
        <Section id='gallery'>
            <SectionWrapper className='wrapper'>
                <TopSection>
                    <Tittle>gallery</Tittle>
                    <SubTitlle>Discover the artistry of concrete in our Photo Gallery. Each picture encapsulates the quality and craftsmanship that defines our projects.</SubTitlle>
                </TopSection>
                <GalleryContainer>
                    <Li>
                        <Image src={gallery10} />
                    </Li>
                    <Li>
                        <Image src={gallery12} />
                    </Li>
                    <Li>
                        <video loop muted autoPlay style={{ width: '100%', height: '100%' }}>
                            <source src={video} type="video/mp4" />
                        </video>
                    </Li>
                    <Li>
                        <Image src={gallery4} />
                    </Li>
                    <Li>
                        <Image src={gallery5} />
                    </Li>
                    
                   
                    <Li>
                        <Image src={gallery6} />
                    </Li>
                    <Li>
                        <Image src={gallery7} />
                    </Li>
                    <Li>
                        <Image src={gallery11} />
                    </Li>
                  
                    <Li>
                        <Image src={gallery} />
                    </Li>
                    <Li>
                        <Image src={gallery8} />
                    </Li>
                    <Li>
                        <Image src={gallery9} />
                    </Li>
                    <Li>
                        <video loop muted autoPlay style={{ width: '100%', height: '100%' }}>
                            <source src={video2} type="video/mp4" />
                        </video>
                    </Li> <Li>
                        <video loop muted autoPlay style={{ width: '100%', height: '100%' }}>
                            <source src={video3} type="video/mp4" />
                        </video>
                    </Li>
                   
                    
                    <Li>
                        <Image src={gallery1} />
                    </Li>
                    <Li>
                        <Image src={gallery2} />
                    </Li>
                    <Li>
                        <Image src={gallery3} />
                    </Li>
                    
                   

                
                </GalleryContainer>
            </SectionWrapper>
        </Section>
    )
}
const Section = Styled.section`
    padding: 20px 0 60px;
`
const SectionWrapper = Styled.div``
const TopSection = Styled.div``
const Tittle = Styled.h1`
    color: #BA181A;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media all and (max-width: 768px) {
        font-size: 34px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`
const SubTitlle = Styled.p`
    color: #000;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 36px;

    @media all and (max-width: 768px) {
    font-size: 18px;
    }
    @media all and (max-width: 640px) {
    font-size: 16px;
    margin-top: 20px;

    }
`
const GalleryContainer = Styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    grid-gap: 30px;
    margin-top: 60px;
    @media all and (max-width: 980px) {
    grid-template-columns: 1fr 1fr 1fr;
        
    }
    @media all and (max-width: 768px) {
    grid-template-columns: 1fr  1fr;
    }
    @media all and (max-width: 640px) {
    margin-top: 30px;
    }
    @media all and (max-width: 480px) {
    grid-template-columns: 1fr ;
    }
`
const Li = Styled.div`
    /* height: 260px; */
`
const Image = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`
const Video = Styled.video`
   
    object-fit: cover;
    display: block;
    /* height="100%"
    width="100%" */
`

