import React from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import Swal from 'sweetalert2';
import BasicMap from '../includes/Map/BasicMap';
import "../../assets/css/style.css";
class ContactForm extends React.Component {
    handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = $("#submit-form").serialize();

            console.log("Form Data:", formData);

            const response = await $.ajax({
                url: "https://script.google.com/macros/s/AKfycbw9R5XRYiZ0FT79zt97DA2ghKkb7ZWUd80osIJ32vX5U3NZbjgJSZ2toDvonrQ2HtjKxQ/exec",
                data: formData,
                method: "post",
            });

            console.log("Response:", response);

            Swal.fire({
                icon: 'success',
                title: 'Form submitted successfully',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                window.location.reload();
            });
        } catch (error) {
            console.error("Error:", error);
            console.error("Error Stack Trace:", error.stack);

            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: 'Please try again later.',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            });
        }
    };

    render() {
        return (
            <Section id='contact'>
                <SectionWrapper className='wrapper'>
                    <TopContainer>
                        <Tittle>get in touch</Tittle>
                        <SubTittle>Connecting with our ready-mix concrete services is easy and efficient. Whether you're planning a project, need a quote, or have inquiries, our team is ready to assist. Reach out to us, and let's collaborate to make your construction aim a success</SubTittle>
                    </TopContainer>
                    <MidContainer>
                        <LeftContainer>
                            <BasicMap />
                        </LeftContainer>
                        <RightContainer>
                            <FormContainer id="submit-form" onSubmit={this.handleSubmit} method="post" name="form1">
                                <FormLi>
                                    <FormInput type="text" placeholder="Name *" name='name' autoComplete="name" required />
                                </FormLi>
                                <FormLi>
                                    <FormInput type="text" placeholder="Email *" name='email' autoComplete="email" required />
                                </FormLi>
                                <FormLi>
                                    <FormInput type="text" placeholder="Phone *" name='phone' autoComplete="tel" required />
                                </FormLi>
                                <FormLi>
                                    <FormInput type="text" placeholder="Message *" name='message' />
                                </FormLi>
                                <FormButton type="submit">Submit</FormButton>
                            </FormContainer>
                        </RightContainer>
                    </MidContainer>
                </SectionWrapper>
            </Section>
        );
    }
}

// ... (rest of your styled components)

export default ContactForm;

const Section = styled.div`
    padding: 60px 0;
    background: #FFF;
    @media all and (max-width: 980px) {
    padding: 20px 0 60px;
}
`;
const SectionWrapper = styled.div``;
const TopContainer = styled.div`
    margin-top: 50px;
    @media all and (max-width: 640px) {
        margin-top: 20px;
    }
`;
const Tittle = styled.h5`
    color: #BA181A;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media all and (max-width: 980px) {
        font-size: 34px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }

`;
const SubTittle = styled.p`
    color: #000;
    text-align: justify;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 36px;
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 640px) {
        margin-top: 20px;
        font-weight: 400;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
        
    }
`;
const MidContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 60px;
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;
const LeftContainer = styled.div`
    width: 50%;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const RightContainer = styled.div`
    width: 50%;
    padding: 50px;
    background: #BA181A;
    min-height: 500px;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const FormContainer = styled.form`
`;
const FormLi = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin-bottom: 30px;
    padding-bottom: 30px;
`;
const FormInput = styled.input`
    display: block;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    &::placeholder {
    color: #FFF;
    }
`;
const FormButton = styled.button`
    border-radius: 5px;
    background: #FFF;
    padding: 10px 14px;
    color: #BA181A;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 30px;
    cursor: pointer;
`;

