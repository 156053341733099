import React from 'react'
import Styled from 'styled-components'
import "../../assets/css/style.css";
import md from "../../assets/images/md.png"


export default function Message() {
    return (
        <Section id='message'>
            <SectionWrapper className='wrapper'>
                <MessageBox>
                    <MdImageContainer><Image src={md} /></MdImageContainer>
                    <DetaileSection>
                        <TopTittle>chairman message</TopTittle>
                        <Quots>As Chairman of Mplus Concrete, it is my pleasure to welcome you to a world of transformative ready-mix concrete solutions. As Chairman, I am proud to lead a team dedicated to shaping the future of ready-mix concrete. Here at Mplus Concrete we don't just provide concrete; we shape foundations for success. Join us in building a future where strength, precision, and environmental responsibility converge harmoniously. Thank you for considering Mplus Concrete as your partner in constructing a flexible and creative tomorrow.</Quots>
                        <Name>Mr. Muhammed</Name>
                    </DetaileSection>
                </MessageBox>
            </SectionWrapper>
        </Section>
    )
}
const Section = Styled.section`
    padding: 90px 0;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
`
const SectionWrapper = Styled.div``
const MessageBox = Styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #CCC;
    padding: 50px 100px;

    @media (max-width: 980px) {
     padding: 50px 50px;
    }
    @media (max-width: 768px) {
        display: block;
    }
    @media (max-width: 480px) {
     padding: 30px 20px;
    }
`
const MdImageContainer = Styled.div`
    width: 253px;
    height: 302px;
    padding: 12px;
    border: 1px solid #D9D9D9;
    @media (max-width: 768px) {
        margin: 0 auto;
    }
    @media (max-width: 640px) {
        width: 176px;
        height: 204px;
    }
`
const Image = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
`
const DetaileSection = Styled.div`
    width: 70%;
    @media (max-width: 980px) {
        width: 63%;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
    }
    
`
const TopTittle = Styled.h3`
    color: #BA181A;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media (max-width: 768px) {
        text-align: center;
        font-size: 34px;
    }
    @media (max-width: 640px) {
        font-size: 26px;
    }
`
const Quots = Styled.p`
    color: #000;
    text-align: justify;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    margin-top: 30px;
    @media (max-width: 980px) {
        font-size: 14px;
        margin-top: 30px;
    }
    @media (max-width: 640px) {
        margin-top: 14px;
    }
`
const Name = Styled.h5`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #BA181A;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    line-height: normal;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 30px;
   @media (max-width: 640px) {
    font-size: 16px;
   }
`




