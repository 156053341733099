import React from 'react'
import Styled from 'styled-components'
import "../../assets/css/style.css";
import settings from '../../assets/icons/settings.svg'
import leaf from '../../assets/icons/leaf.svg'
import villa from '../../assets/icons/villa.svg'
import time from '../../assets/icons/time.svg'
import cost from '../../assets/icons/cost.svg'
import backgroun from '../../assets/images/whyus-bg.png'

export default function WhyUs() {
  return (
    <Section id='why-us'>
        <SectionWrapper className='wrapper' >
            <Top><Tittle>Why Choose Us</Tittle></Top>
            <Ul>
                <Li>
                    <ImgContainer><Icon src={settings}/></ImgContainer>
                    <Text>Advanced Technology</Text>
                </Li>
                <Li>
                    <ImgContainer><Icon src={villa}/></ImgContainer>
                    <Text>Durable Concrete</Text>
                </Li> <Li>
                    <ImgContainer><Icon src={time}/></ImgContainer>
                    <Text>Timely Delivery</Text>
                </Li> <Li>
                    <ImgContainer><Icon src={cost}/></ImgContainer>
                    <Text>Cost Effective</Text>
                </Li> <Li>
                    <ImgContainer><Icon src={leaf}/></ImgContainer>
                    <Text>Environment Friendly</Text>
                </Li>
            </Ul>
        </SectionWrapper>
    </Section>
  )
}
const Section = Styled.section`
    background: url(${backgroun}) no-repeat; /* Specify the background image */
    background-size: cover;
    background-position: center center; 
    background-color: rgb(0, 0, 0, 0.70);
    background-blend-mode: multiply;
    padding: 100px 0 60px;
     @media (max-width: 768px) {
        display: none;
    }
`
const SectionWrapper = Styled.div``
const Top = Styled.div``
const Tittle = Styled.h4`
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
`
const Ul = Styled.ul`
    margin-top: 60px;
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
   
`
const Li = Styled.li`
    text-align: center;
`

const Text = Styled.p`
    margin-top: 50px;
    color: #F5F5F5;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    width: 70%;
    margin:  50px auto ;
    @media (max-width: 980px) {
        font-size: 13px;
        margin:  10px auto ;
        
    }
`
const ImgContainer = Styled.div`
    display: inline-block;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 5px;
    width: 100px;
`
const Icon = Styled.img`
    background: #fff;
    border-radius: 50px;
    padding: 8px;
    width: 86px;
    object-fit: contain;
    display: block;
`
