import React, { useState } from 'react';
import Styled from 'styled-components';
import "../../assets/css/style.css";
import logo from '../../logo.png';
import menuIcon from '../../assets/icons/menu.svg';
import NavBar from '../includes/NavBar';

export default function Header() {
    const [isMenu, setMenu] = useState(false);

    const handleLinkClick = (id) => {
        const targetElement = document.getElementById(id.toLowerCase());
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
        setMenu(false);
    };
    return (
        <>
            <NavBar isMenu={isMenu} setMenu={setMenu} />
            <HContainer>
                <Container className='wrapper'>
                    <Left>
                        <H1>
                            <Acontainer>
                                <Link onClick={() => handleLinkClick("spotlight")}>
                                    <LogoImage
                                        src={logo}
                                        alt='logo'
                                    />
                                </Link>
                            </Acontainer>
                        </H1>
                    </Left>
                    <Right>
                        <Ul>
                            <LI><A onClick={() => handleLinkClick("spotlight")}>Home</A></LI>
                            <LI><A onClick={() => handleLinkClick("about")}>ABOUT US</A></LI>
                            <LI><A onClick={() => handleLinkClick("service")}>SERVICES</A></LI>
                            <LI><A onClick={() => handleLinkClick("operation")}>MODE OF OPERATION</A></LI>
                            <LI><A onClick={() => handleLinkClick("why-us")}>WHY CHOOSE US</A></LI>
                            <LI><A onClick={() => handleLinkClick("gallery")}>gallery</A></LI>
                            {/* <LI><A onClick={() => handleLinkClick("testimonial")}>testimonials</A></LI> */}
                            <LI><A onClick={() => handleLinkClick("contact")}>contacts</A></LI>
                        </Ul>
                        <MenuIcon onClick={() => setMenu(!isMenu)}>
                            <MenuImG
                                src={menuIcon}
                                alt="menu icon"
                            />
                        </MenuIcon>
                    </Right>
                </Container>
            </HContainer>
        </>
    )
}

const HContainer = Styled.div`
    width: 100%;
    height: 120px;
    background: #fff;
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;  
    @media (max-width: 480px) {
    height: 90px;
        
    }
`
const Container = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const Left = Styled.div`

`
const H1 = Styled.h1`
    cursor: pointer;
`
const Acontainer = Styled.a`
    display: inline-block;

`
const Link = Styled.div`
    width: 180px;
    /* height:80px; */
    @media (max-width: 480px) {
        width: 130px;
    }
`;

const LogoImage = Styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const Right = Styled.div`

`
const Ul = Styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 1280px) {
        display: none;
    }
`
const LI = Styled.li`
    cursor: pointer;
`
const A = Styled.a`
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 30px;
    color: #000;
    display: inline-block;
    font-family: 'Inter', sans-serif;
    color: #3E3E3E;
    font-style: normal;
    font-weight: 300;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        color: #BA181A;
    }
`
const MenuIcon = Styled.div`
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: none;
    @media all and (max-width: 1280px) {
        display: block;
    }
`;
const MenuImG = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;
