import Header from "./components/general/Header";
import Spotlight from "./components/screens/Spotlight";
import AboutPage from "./components/screens/About";
import Message from "./components/screens/Message";
import Service from "./components/screens/Service";
import Operation from "./components/screens/Operation";
import WhyUs from "./components/screens/WhyUs";
import Equipments from "./components/screens/Equipments";
import Gallery from "./components/screens/Gallery";
import Testimonial from "./components/screens/testimonial/Testimonial";
import Faq from "./components/screens/Faq";
import Contact from "./components/screens/Contact";
import Footer from "./components/general/Footer";


function App() {
  return (
    <>
      <Header />
      <Spotlight />
      <AboutPage />
      <Message />
      <Service />
      <Operation />
      <WhyUs />
      <Equipments />
      <Gallery />
      {/* <Testimonial /> */}
      <Faq />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
