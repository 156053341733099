import React from 'react'
import Styled from 'styled-components'
import "../../assets/css/style.css";
import bgimage from "../../assets/images/spotlight-bg.png";
import arrow from "../../assets/icons/arrow_circle.svg"
import spotlight from "../../assets/images/Spotlight2.png"
import settings from "../../assets/icons/handyman.svg"
import price from "../../assets/icons/price.svg"



export default function Spotlight() {
    const handleLinkClick = (id) => {
        const targetElement = document.getElementById(id.toLowerCase());
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <MainContainer id="spotlight" >
            <Box className='wrapper'>
                <H2>MIXING TRUST, POURING STRENGTH</H2>
                <H1>Concrete mastery in every mix</H1>
                <P>Elevate Your Structures with Our Superior Ready-Mix Concrete Where Precision Meets Performance</P>
                <Button onClick={() => handleLinkClick("service")}>Services <Span><Icon src={arrow} /></Span></Button>
                <Bottom>
                    <Cards>
                        <First>
                            <Top> <Icon src={price} /></Top>
                            <Text>Affordable price</Text>

                        </First>

                        <Second>
                            <Top> <Icon src={settings} /></Top>
                            <Text color='#BA181A'>experienced employees</Text>
                        </Second>
                    </Cards>
                </Bottom>
            </Box>
            <SpotlightImage>
                    <SImage src={spotlight} alt="bgimage" />
                </SpotlightImage>
        </MainContainer>
    )
}

const MainContainer = Styled.div`
    width: 100%;
    padding-top: 170px;
    background: url(${bgimage}) no-repeat; 
    background-size: cover;
    background-position: center center; 
    /* height: calc(100vh - 120px); */
    height: 100vh;
    position: relative;
    @media all and (max-width: 1380px) {
        padding-top: 150px;
    }
    @media all and (max-width: 1280px) {
        padding-top: 140px;
        height: 100vh;
    }
    @media all and (max-width: 1080px) {
        padding-top: 130px;
    }
        @media all and (max-width: 980px) {
        padding-top: 140px;
    }
    @media all and (max-width: 988px) {
        background: none;
        height: auto;
    }

`
const Box = Styled.div``;
const H2 = Styled.h1`
    font-family: 'Inter', sans-serif;
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration:uppercase;
    @media all and (max-width: 1380px) {
        font-size: 48px;
    }
    @media all and (max-width: 980px) {
        font-size: 34px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 25px;
    }
    @media all and (max-width: 430px) {
        font-size: 20px;
    }
`
const H1 = Styled.h1`
    font-family: "Josefin";
    color: #D60000;
    font-size: 54px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    width: 64%;
    margin-top: 30px;
    @media all and (max-width: 1380px) {
        font-size: 74px;
    }
    @media all and (max-width: 1280px) {
        font-size: 58px;
        width: 55%;
    }
    @media all and (max-width: 1180px) {
        font-size: 54px;
        width: 70%;
    }
    @media all and (max-width: 980px) {
        font-size: 44px;
    }

    @media all and (max-width: 780px) {
        width: 90%;
        font-size: 44px;
    }
    @media all and (max-width: 640px) {
        font-size: 30px;
    }
    @media all and (max-width: 480px) {
        font-size: 26px;
    }
`
const P = Styled.p`
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 10px;
    width: 50%;
    @media all and (max-width: 1180px) {
    font-size: 22px;
    }
    @media all and (max-width: 980px) {
        line-height: 1.5rem;
        font-size: 20px;
    }
    @media all and (max-width: 640px) {
    width: 90%;
    font-size: 18px;
    }
    @media all and (max-width: 430px) {
    font-size: 14px;
    line-height: 1.2rem;
    }
`
const Button = Styled.a`
    width: max-content;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
    background: #BA181A;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 30px;
    @media all and (max-width: 640px) {
    font-size: 12px;
    padding: 5px 9px;
    }
`
const Span = Styled.span`
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-left: 20px;
    @media all and (max-width: 640px) {
        width: 24px;
        height: 24px;
        margin-left: 10px;
    }
`
const Icon = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`
const SpotlightImage = Styled.div`
    width: 100%;
    display: none;
    @media all and (max-width: 980px) {
        display: block;
    }
`;
const SImage = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
`;
const Bottom = Styled.div`
    position: absolute;
    top: 95%;
    right: 20%;
    @media all and (max-width: 1280px) {
    right: 5%;
    }
    @media all and (max-width: 768px) {
        display: none;
    }
`
const Cards = Styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

`
const First = Styled.div`
    color: #FFF;
    text-align: center;
    padding: 20px 70px;
    border-radius: 20px;
    background: #BA181A;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.10);

`
const Second = Styled.div`
    color: #BA181A;
    text-align: center;
    padding: 20px 40px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.10);
    margin-left: 20px;
`

const Top = Styled.div`
    width: 34px;
    height: 34px;
    margin: 0 auto;
`
const Text = Styled.p`
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    color: currentColor;
`


