import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/icons/Footer-logo.png'
import fb from '../../assets/icons/fb.svg'
import insta from '../../assets/icons/insta.svg'
import location from '../../assets/icons/location.svg'
import Whatsapp from '../../assets/icons/wapp.svg'
import phone from '../../assets/icons/phone.svg'
import mail from '../../assets/icons/mail.svg'

export default function Footer() {

    const handleLinkClick = (id) => {
        const targetElement = document.getElementById(id.toLowerCase());
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }

    };
    return (
        <>
            <Section>
                <SectionWrapper className='wrapper' >
                    <SectionTop>
                        <FooterLogo onClick={() => handleLinkClick("spotlight")}>
                            <LogoImage
                                src={logo}
                                alt='logo'
                            />
                        </FooterLogo>
                        <MainContainer>
                            <Items>
                                <Text>Elevate your construction projects in Kerala with our top-notch ready mix concrete services.</Text>
                                <SocialContainer>
                                    <Social href='https://www.facebook.com/mplusconcrete?mibextid=2JQ9oc'>
                                        <SIcon src={fb} alt='Facebook' />
                                    </Social>
                                    <Social href='https://www.instagram.com/mplus_concrete?igsh=MTVqaGJkdTczODJ1dg=='>
                                        <SIcon src={insta} alt='Instagram' />
                                    </Social>
                                    <Social href="https://wa.me/9847246664" target="_blank">
                                        <SIcon src={Whatsapp} alt='WhatsApp' />
                                    </Social>
                                </SocialContainer>
                            </Items>
                            <Items>
                                <LinkTittle>Quick Links</LinkTittle>
                                <QLink>
                                    <Link onClick={() => handleLinkClick("spotlight")}>Home</Link>
                                    <Link onClick={() => handleLinkClick("about")}>About us </Link>
                                    <Link onClick={() => handleLinkClick("service")}>Services</Link>
                                    <Link onClick={() => handleLinkClick("gallery")}>gallery</Link>
                                    <Link onClick={() => handleLinkClick("faq")}>FAQ</Link>
                                </QLink>
                            </Items>
                            <Items>
                                <ContactMe>
                                    <Item>
                                        <Icon src={location} alt='location' />
                                        <ItemText>
                                            M/s. MALABAR READY MIX (RMC)
                                            19/38, Manjeri Municipality,
                                            Malabar Building, Kuttipara,
                                            Payyanad, Malappuram-676 122
                                        </ItemText>
                                    </Item>
                                    <Item>
                                        <Icon src={phone} alt='phone' />
                                        <ItemText href='tel:+91 759 389 3301' target='_blank'>
                                        +91 759 389 3301 
                                        </ItemText>
                                    </Item>
                                    <Item>
                                        <Icon src={phone} alt='phone' />
                                        <ItemText href='tel:+91 9061 600 500' target='_blank'>
                                        +91 9061 600 500
                                        </ItemText>
                                    </Item>
                                    <Item>
                                        <Icon src={mail} alt='mail' />
                                        <ItemText href='mailto:info@mplusconcrete.com' target='_blank'>
                                            info@mplusconcrete.com
                                        </ItemText>
                                    </Item>
                                </ContactMe>
                            </Items>
                        </MainContainer>
                    </SectionTop>

                </SectionWrapper>
            </Section>
            <Bottom>
                <Copyright>
                    © All Rights Reserved | Designed And Developed By <A href="https://www.tigrid.in">TIGRID</A>
                </Copyright>
            </Bottom>
        </>
    )
}

const Section = styled.div`
    background: #BA181A;
    padding: 70px 0;
`;
const SectionWrapper = styled.div``;
const SectionTop = styled.div``;
const FooterLogo = styled.div`
    width: 160px;
    cursor: pointer;
`;
const LogoImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;
const MainContainer = styled.div`
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    @media (max-width: 980px) {
        grid-template-columns: 1fr ;
    }
     @media all and (max-width: 640px) {
        grid-gap: 40px;
        
    }
`;

const Items = styled.div`

`;
const Text = styled.p`
    color: #FFF;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 80%;
    @media all and (max-width: 480px) {
        width: 100%;
        font-size: 16px;
    }
`;
const SocialContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
   @media all and (max-width: 640px) {
        margin-top: 30px;
   }
`;
const Social = styled.a`
    width: 44px;
    height: 44px;
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;

    &:first-child {
        margin-left: 0;
    }
    @media all and (max-width: 640px) {
        width: 34px;
        height: 34px;
    }
`;
const SIcon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;
const QLink = styled.div`
    display: grid;
    grid-gap: 20px;
`;
const LinkTittle = styled.h5`
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 40px;
    @media all and (max-width: 640px) {
        font-size: 18px;
    margin-bottom: 20px;

    }
`;
const Link = styled.a`
    display: inline-block;
    color: #D9D9D9;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform:capitalize;
    cursor: pointer;
       
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const ContactMe = styled.div``;
const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
`;
const Icon = styled.img`
    width: 24px;
    /* height: 24px; */
    display: block;
    object-fit: contain;
`;
const ItemText = styled.a`
    color: #FFF;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: inline-block;
    margin-left: 20px;
 
`;
const Bottom = styled.div`
    padding: 14px 0;
    text-align: center;
`;
const Copyright = styled.p`
   color: #5F5F5F;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    @media all and (max-width: 480px) {
    font-size: 12px;
    }
`;
const A = styled.a`
    color: #BA181A;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
      @media all and (max-width: 480px) {
    font-size: 12px;
    }
`;

