import React from 'react';
import Styled from 'styled-components';
import "../../../assets/css/style.css";
import TestimonialSlider from './TestimonialSlider';

export default function Testimonial() {

    return (
        <Section id='testimonial'>
            <SectionRedBox>
                <SectionWrapper className='wrapper'>
                    <Tittle>our clients say</Tittle>
                    <Container>
                        <LeftContainer>
                            <SubText>OUR CREDIBILITY HAS BEEN PROVEN</SubText>
                        </LeftContainer>
                        <RightContainer>
                            <Explain>With a proven track record of reliability, quality assurance, and timely deliveries, we have earned the trust of our clients. Choose us for concrete solutions you can depend on for the success of your construction projects.</Explain>
                        </RightContainer>
                    </Container>
                    <SliderContainer >

                        <TestimonialSlider />

                    </SliderContainer>
                </SectionWrapper>
            </SectionRedBox>
        </Section>
    );
}

const Section = Styled.section``;
const SectionRedBox = Styled.div`
    background: #BA181A;
    padding: 80px 0;
    @media all and (max-width: 640px) {
    padding: 40px 0;
        
    }
`;

const SectionWrapper = Styled.div``;
const Container = Styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 20px;
    @media all and (max-width: 980px) {
        display: block;
    }
`;
const LeftContainer = Styled.div``;
const Tittle = Styled.h2`
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
    @media all and (max-width: 640px) {
    font-size: 20px;
        
    }
   
`;
const SubText = Styled.p`
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media all and (max-width: 980px) {
        margin-bottom: 10px;
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const RightContainer = Styled.div``;
const Explain = Styled.p`
    color: #FFF;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
`;
const SliderContainer = Styled.div`
    margin-top: 60px;
`;
