import React from 'react'
import Styled from 'styled-components'
import "../../assets/css/style.css";
import equipments from '../../assets/images/equipments.png'

export default function Equipments() {
  return (
    <Section id='equipments'>
     <SectionWrapper className='wrapper' >
        <ItemContainer>
        <RightItem>
            
                <ImgContainer>
                <Img src={equipments} />
                </ImgContainer>
            </RightItem>
            <LeftItem>
            <OperationTop>
            <Heading>equipments</Heading>
            <Subheading>At M plus Concrete, we use top-notch equipment for our ready-mix concrete, our advanced batching technology and well-maintained delivery fleet are the backbone of our operations</Subheading>
        </OperationTop>
                <LeftUl>
                    <Li>
                        <Left><RedDot></RedDot></Left>
                        <Right>
                            <TopTittle>Control Room</TopTittle>
                            <Content>The ‘brain’ of the plant that calculates and sends out the exact measurements required to each sub station, allowing them to create the specified mix.</Content>
                        </Right>
                    </Li>
                    <Li>
                        <Left><RedDot></RedDot></Left>
                        <Right>
                            <TopTittle>Aggregate Bins</TopTittle>
                            <Content>This is where all the different types of aggregates are stored. Here, the exact amount of the required materials are weighed out, then carried via a conveyor belt to the mixing station ready to be mixed together.</Content>
                        </Right>
                    </Li>
                    <Li>
                        <Left><RedDot></RedDot></Left>
                        <Right>
                            <TopTittle>Cement Silos</TopTittle>
                            <Content>Where the cement is stored, weighed out and transferred to the mixing station ready to be mixed along with the aggregates.</Content>
                        </Right>
                    </Li>
                    <Li>
                        <Left><RedDot></RedDot></Left>
                        <Right>
                            <TopTittle>Water & Additives</TopTittle>
                            <Content>Once water is added to the dry ingredients, the wet concrete mix itself starts to form. Additives are also added for extra strength, faster curing or better workability.</Content>
                        </Right>
                    </Li>
                    <Li>
                        <Left><RedDot></RedDot></Left>
                        <Right>
                            <TopTittle>The Mixing Station</TopTittle>
                            <Content>Here, all the dry ingredients are mixed together with the wet ingredients until the correct consistency is formed.</Content>
                        </Right>
                    </Li>
                   
                </LeftUl>
            </LeftItem>
          
        </ItemContainer>
     </SectionWrapper>
   </Section>
  )
}

const Section = Styled.section`
    padding: 80px 0 ;
    @media all and (max-width: 640px) {
        padding: 40px 0 ;
        
    }
`
const SectionWrapper = Styled.div``
const OperationTop = Styled.div``
const Heading = Styled.h4`
    color: #BA181A;
    font-size: 40px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media all and (max-width: 768px) {
        font-size: 34px;  
    }
    @media all and (max-width: 640px) {
        font-size: 28px;  
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`
const Subheading = Styled.p`
    color: #282828;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    margin-top: 20px;
`
const ItemContainer = Styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 2fr;
    grid-gap: 80px;
    @media all and (max-width: 768px) {
    grid-template-columns:  2fr;
    }
`
const LeftItem = Styled.div``
const RightItem = Styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`
const LeftUl = Styled.ul`
    margin-top: 60px;
`
const Li = Styled.li`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
    @media all and (max-width: 640px) {
    margin-top: 30px;
        
    }
`
const Left = Styled.div`
   
`
const RedDot = Styled.span`
    width: 12px;
    height: 12px;
    background: #BA181A;
    display: inline-block;
    margin-top: 4px;
`
const Right = Styled.div`
    margin-left: 30px;
`
const ImgContainer = Styled.div`

`
const Img = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`
const TopTittle = Styled.h5`
    color: #292929;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    @media all and (max-width: 480px) {
    font-size: 16px;
    }
`
const Content = Styled.p`
    color: #282828;
    text-align: justify;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 20px;
    text-align: justify;
    @media all and (max-width: 640px) {
        font-size: 16px;
    margin-top: 14px;

    }
`



