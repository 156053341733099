import React, { useState } from 'react';
import Styled from 'styled-components';


const AccordionItem = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  return (
    <AccordionItemWrapper >
      <AccordionItemHeader onClick={toggleAccordion} className={isActive ? 'active' : ''}>
        <AccordianTittle>{title}</AccordianTittle>
        <AccordionItemIcon>{isActive ? '−' : '+'}</AccordionItemIcon>
      </AccordionItemHeader>
      <AccordionItemBody style={{ maxHeight: isActive ? '100%' : '0' }}>
        <AccordionItemBodyContent>{content}</AccordionItemBodyContent>
      </AccordionItemBody>
    </AccordionItemWrapper>
  );
};

const AccordionContainer = Styled.div`
  width: 100%;
  transition: 0.2s ease-out;

`;

const AccordionItemWrapper = Styled.div`
  /* border: 1px solid #ddd; */
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  transition: 0.2s ease-out;

`;

const AccordionItemHeader = Styled.div`
  position: relative;
  padding: 20px;
  background: #EAEAEA;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s ease-out;

  &.active {
  transition: 0.2s ease-out;
  }
 
`;
const AccordianTittle = Styled.h4`
  font-size: 18px;
  width: 90%;
  line-height: 1.5rem;

   @media (max-width: 580px) {
    font-size: 16px;
    width: 80%;
  }
`;
const AccordionItemIcon = Styled.div`
    font-size: 28px;
    position: absolute;
    right: 1rem;
    /* padding: 1px 9px; */
    border-radius: 50%;
    /* background: #BA181A; */
    color: #BA181A;
    transition: 0.2s ease-out;
    margin-left: 1rem;
    @media (max-width: 640px) {
    padding: 1px 6px;
    font-size: 18px;
    }

`;

const AccordionItemBody = Styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.active ? '100%' : '0')};
  background: #EAEAEA;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.20);
  transition: 0.2s ease-out;

`;

const AccordionItemBodyContent = Styled.div`
  padding: 2rem;
  line-height: 1.5rem;
  border: 1px solid #E3E3E3;
  background: rgba(255, 255, 255, 0.50);
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  color: #000;
  transition: 0.2s ease-out;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 1.5rem;
  }
 
`;

const Accordion = () => {
  const accordionItems = [
    {
      title: 'What sets Mplus Concrete apart from other suppliers? ',
      content:
        'At Mplus Concrete, we distinguish ourselves through a commitment to excellence. Our focus on precision, craftsmanship, and client-centric solutions ensures that every project benefits from top-tier ready-mix concrete and unparalleled service.',
    },
    {
      title: 'How can I request a quote for my project?',
      content:
        'Requesting a quote is simple. Visit our website or contact our customer service, provide project details, and our team will promptly provide you with a customized quote tailored to your specific requirements.',
    },
    {
      title: 'Are your concrete mix designs customizable?',
      content:
        'Absolutely. Mplus Concrete takes pride in offering project-specific mix designs. Our team collaborates closely with you to understand your needs, ensuring that the ready-mix concrete delivered aligns perfectly with your projects unique specifications.',
    },
    {
      title: 'What measures do you take to ensure the quality of your concrete?',
      content:
        'Quality is paramount at Mplus Concrete. We source high-quality raw materials, implement rigorous quality control at every stage, and conduct thorough testing to ensure that our ready-mix concrete meets and exceeds industry standards.',
    },
    {
      title: 'How do you ensure timely delivery of ready-mix concrete?',
      content:
        'Timely delivery is a priority for us. Mplus Concrete optimizes transportation routes, schedules deliveries efficiently, and invests in a reliable supply chain, ensuring that your ready-mix concrete arrives precisely when and where you need it.',
    },
    {
      title: 'What environmental practices does Mplus Concrete follow?',
      content:
        'We are committed to environmental responsibility. Mplus Concrete practices eco-conscious sourcing, sustainable production methods, and complies with all relevant environmental regulations, making us a leader in green construction solutions.',
    },
    {
      title: 'Can I get technical assistance for my project?',
      content:
        'Absolutely. Mplus Concrete provides expert technical support. Our team of seasoned professionals is dedicated to assisting you comprehensively, ensuring that your ready-mix concrete solutions are not just delivered but optimized for success.',
    },
    {
      title: 'How does Mplus Concrete contribute to cost-effective construction?',
      content:
        'We prioritize cost-effective solutions without compromising on quality. Mplus Concrete s competitive pricing model ensures that your construction projects benefit from both affordability and top-notch ready-mix concrete.',
    },
    {
      title: 'Is Mplus Concrete available for small-scale projects?',
      content:
        'Yes, we cater to projects of all sizes. Whether it`s a large-scale construction or a smaller endeavor, Mplus Concrete is equipped to provide the same level of precision, quality, and dedicated service.',
    },
    {
      title: 'How can I get in touch with Mplus Concrete for further inquiries?',
      content:
        'For any inquiries, you can contact our customer service through the contact details provided on our website. Our team is ready to assist you with any additional information or support you may need.',
    },

  ];

  return (
    <AccordionContainer>
      {accordionItems.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
