import React, { useEffect, useRef } from 'react';
import Styled from 'styled-components';
import "../../assets/css/style.css";
import aboutimage from "../../assets/images/aboutbg.png"
import mission from "../../assets/icons/mission.svg"
import vision from "../../assets/icons/vision.svg"
// import Integrity from "../../assets/icons/"
import dedication from "../../assets/icons/Dedication.svg"
import empathy from "../../assets/icons/Empathy.svg"
import positivity from "../../assets/icons/Positivity.svg"
import responsiveness from "../../assets/icons/Responsiveness.svg"
import safety from "../../assets/icons/Safety.svg"
import innovate from "../../assets/icons/Innovate.svg"
import trust from "../../assets/icons/Trust.svg"



export default function AboutPage() {
    const experianceSectionRef = useRef(null);

    useEffect(() => {
        // Function to handle the counting animation
        function runCountAnimation() {
            let valueDisplays = document.querySelectorAll(".num");
            let interval = 4000;

            valueDisplays.forEach((valueDisplay) => {
                let startValue = 0;
                let endValue = parseInt(valueDisplay.getAttribute("data-val"));
                let duration = Math.floor(interval / endValue);
                let counter = setInterval(function () {
                    startValue += 1;
                    valueDisplay.textContent = startValue;
                    if (startValue === endValue) {
                        clearInterval(counter);
                    }
                }, 50); // Increase the delay to slow down the counting
            });
        }

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    runCountAnimation();
                    observer.disconnect();
                }
            });
        }, { threshold: 0.9 });

        if (experianceSectionRef.current) {
            observer.observe(experianceSectionRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            if (experianceSectionRef.current) {
                observer.unobserve(experianceSectionRef.current);
            }
        };
    }, []);

    return (
        // <div className="about-page">
        //   {/* Other content goes here */}
        //   <div className="experiance" ref={experianceSectionRef}>
        //     {/* Content for the section */}
        //     <span className="num" data-val="100">0</span>
        //     {/* Add more elements with the 'num' class and 'data-val' attribute as needed */}
        //   </div>
        // </div>

        <Container className="about-page" id="about">
            <SubContainer className='wrapper'>
                <Left>
                    <ImageContainer>
                        <Img src={aboutimage} alt="image" />
                    </ImageContainer>
                </Left>
                <Right>
                    <Heading>ABOUT US</Heading>
                    <SubHead>Pioneering Excellence in Ready Mix Solutions</SubHead>
                    <Paragrph>Welcome to Mplus Concrete, your premier destination for top-quality ready-mix concrete solutions. As a distinguished ready-mix concrete provider, we are dedicated to delivering top-tier products and services that elevate your building projects to new heights.</Paragrph>
                    <Paragrph>Our journey is defined by a fusion of experience and innovation. With state-of-the-art facilities and a team of seasoned experts, we ensure that every mix is a perfect blend of strength, durability, and performance. From conception to completion, we understand the unique needs of each project, offering customized solutions that not only meet but elevate your expectations.</Paragrph>
                    <Paragrph>Beyond the business of concrete, we're in the business of building enduring relationships. When you choose Mplus Concrete, you're not just selecting a supplier; you're partnering with a team dedicated to the seamless realization of your vision. Experience the strength of our concrete, the reliability of our service, and the foundation of a future built on excellence.</Paragrph>
                </Right>
            </SubContainer>

            <CountBox >
                <Box className='wrapper'>
                    <Experiance className="wrapper experiance" ref={experianceSectionRef}>
                        <Span className="num" data-val="254">0</Span>
                        <Title>Completed Projects</Title>
                    </Experiance>
                    <Experiance className="wrapper experiance" ref={experianceSectionRef}>
                        <Span className="num" data-val="35">0</Span>
                        <Title>Locations</Title>
                    </Experiance>
                    <Experiance className="wrapper experiance" ref={experianceSectionRef}>
                        <Span className="num" data-val="54">0</Span>
                        <Title>Total Team</Title>
                    </Experiance>
                </Box>
            </CountBox>

            <ValuContainer>
                <ValuWrapper className="wrapper">
                    <LeftBox>
                        <RedContainer>
                            <ItemBox>
                                <TopSection>
                                    <Icon src={mission} />
                                    <Text>mission</Text>
                                </TopSection>
                                <Detiles>Our mission at Mplus Concrete is to be the cornerstone of construction reliability, innovation, and sustainability. We strive to empower our clients with foremost ready-mix concrete solutions, ensuring that their projects not only meet but exceed expectations. Committed to precision in every mix, we aim to redefine industry standards and contribute to the creation of resilient structures that stand the test of time. At Mplus Concrete, we visualize a future where every project reflects the strength of our mission and the solidity of our foundations</Detiles>
                            </ItemBox>
                            <ItemBox>
                                <TopSection>
                                    <Icon src={vision} />
                                    <Text>vision</Text>
                                </TopSection>
                                <Detiles>Our mission at Mplus Concrete is to be the cornerstone of construction reliability, innovation, and sustainability. We strive to empower our clients with foremost ready-mix concrete solutions, ensuring that their projects not only meet but exceed expectations. Committed to precision in every mix, we aim to redefine industry standards and contribute to the creation of resilient structures that stand the test of time. At Mplus Concrete, we visualize a future where every project reflects the strength of our mission and the solidity of our foundations</Detiles>
                            </ItemBox>
                        </RedContainer>
                    </LeftBox>
                    <RightBox>
                        <RightContainer>
                            <RightTopSection>
                                <RightTittle>
                                    OUR VALUES
                                </RightTittle>
                                <Ul>
                                    <Li><IContainer><ItemIcon src={dedication} /></IContainer> <ItemText>Integrity</ItemText> </Li>
                                    <Li><IContainer><ItemIcon src={dedication} /></IContainer> <ItemText>Dedication & Commitment and Teamwork</ItemText> </Li>
                                    <Li><IContainer><ItemIcon src={empathy} /></IContainer> <ItemText>Empathy & Care</ItemText> </Li>
                                    <Li><IContainer><ItemIcon src={positivity} /></IContainer> <ItemText>Positivity</ItemText> </Li>
                                    <Li><IContainer><ItemIcon src={responsiveness} /></IContainer> <ItemText>Responsiveness</ItemText> </Li>
                                    <Li><IContainer><ItemIcon src={safety} /></IContainer> <ItemText>Safety</ItemText> </Li>
                                    <Li><IContainer><ItemIcon src={innovate} /></IContainer> <ItemText>Innovate and improve to Excellence</ItemText> </Li>
                                    <Li><IContainer><ItemIcon src={trust} /></IContainer> <ItemText>Trust</ItemText> </Li>
                                </Ul>
                            </RightTopSection>
                        </RightContainer>
                    </RightBox>
                </ValuWrapper>
            </ValuContainer>
        </Container>


    );
}
const Container = Styled.div`
    padding: 170px 0 0 0;
    @media (max-width: 768px) {
        padding: 80px 0 0 0;
    }
    @media (max-width: 640px) {
        padding: 30px 0 0 0;
    }
`
const SubContainer = Styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #fff;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`
const Left = Styled.div`
    width: 48%;
    @media (max-width: 768px) {
        width: 100%;
        margin: 33px auto;
    }
`
const Right = Styled.div`
    width: 48%;
    margin-left: 5%;
    @media (max-width: 768px) {
    width: 100%;
    margin-left:0 ;
    }

`
const ImageContainer = Styled.div`
`
const Img = Styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
`
const Heading = Styled.h4`
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 640px) {
        font-size: 24px;
    }
`
const SubHead = Styled.h5`
    color: #BA181A;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
    @media (max-width: 1180px) {
        font-size: 34px; 
    }
    @media (max-width: 640px) {
        font-size: 28px;
    }
    @media (max-width: 480px) {
        font-size: 22px;
        
    }
   
`
const Paragrph = Styled.p`
    color: #000;
    text-align: justify;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 20px;
    @media (max-width: 1280px) {
        font-size: 16px;
    }
    @media  (max-width: 1080px) {
        font-size: 14px; 
    }
`
const CountBox = Styled.div`
    background: #BA181A;
    padding: 30px 0;
    width: 85%;
    border-top-right-radius: 20px;

    @media  (max-width: 480px) {
    width: 100%;
    border-top-right-radius: 0;
    }
`
const Box = Styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 480px) {
        align-items: flex-start;
    }
`
const Experiance = Styled.div``
const Span = Styled.span`
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 1080px) {
        font-size: 50px;
    }
    @media (max-width: 640px) {
        font-size: 40px;
    }
    @media (max-width: 480px) {
        font-size: 30px;
    }
`
const Title = Styled.p`
    color: #BEBEBE;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1080px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        width: 60%;
    }
    @media (max-width: 640px) {
        font-size: 12px;
    }
`
const ValuContainer = Styled.div`
    background: #F4F4F4;
    padding: 120px 0;
    @media (max-width: 640px) {
    padding: 65px 0;
        
    }
`
const ValuWrapper = Styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    @media (max-width: 1080px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
const LeftBox = Styled.div``
const RightBox = Styled.div``
const RedContainer = Styled.div`
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #BA181A;
    padding: 40px 30px;
`
const ItemBox = Styled.div`
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0px;
    }
`
const TopSection = Styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
`
const Icon = Styled.img`
    background: #BA181A;
    display: inline-block;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
`
const Text = Styled.h5`
    color: #000;
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
`
const Detiles = Styled.p`
    color: #000;
    text-align: justify;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    @media (max-width: 1080px) {
        font-size: 14px;
    }
`
const RightContainer = Styled.div``
const RightTopSection = Styled.div``
const RightTittle = Styled.h5`
    color: #BA181A;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 20px;
    @media (max-width: 980px) {
        font-size: 36px;
    }
    @media (max-width: 640px) {
        font-size: 30px;
    }
    @media (max-width: 480px) {
        font-size: 26px;
    }
`
const Ul = Styled.ul`
    @media (max-width: 980px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        
    }
`
const Li = Styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 35px;
`
const IContainer = Styled.div`
    width: 44px;
    height: 44px;
    background: #BA181A;
    border-radius: 4px;
    padding: 6px;
`
const ItemIcon = Styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
`
const ItemText = Styled.h4`
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 15px;
    @media (max-width: 1080px) {
        font-size: 18px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
`




