import React from "react";
import styled from "styled-components";

function NavBar({ isMenu, setMenu }) {

    const handleLinkClick = (id) => {
        const targetElement = document.getElementById(id.toLowerCase());
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
        setMenu(false);
    };
    return (
        <>
            {isMenu && (
                <Container className={isMenu && "slide"}>
                    <Middle>
                        <Ul>
                            <LI><A onClick={() => handleLinkClick("spotlight")}>Home</A></LI>
                            <LI><A onClick={() => handleLinkClick("about")}>ABOUT US</A></LI>
                            <LI><A onClick={() => handleLinkClick("service")}>SERVICES</A></LI>
                            <LI><A onClick={() => handleLinkClick("operation")}>MODE OF OPERATION</A></LI>
                            <LI><A onClick={() => handleLinkClick("why-us")}>WHY CHOOSE US</A></LI>
                            <LI><A onClick={() => handleLinkClick("equipments")}>gallery</A></LI>
                            {/* <LI><A onClick={() => handleLinkClick("testimonial")}>testimonials</A></LI> */}
                            <LI><A onClick={() => handleLinkClick("contact")}>contacts</A></LI>
                        </Ul>
                    </Middle>
                </Container>
            )}
        </>
    );
}

export default NavBar;
const Container = styled.div`
    position: fixed;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    width: 100vw;
    height: 150vh;
    top: 0px;
    right: 0px;
    padding: 120px 50px;
    box-sizing: border-box;
    transition: all 400ms ease-in-out 0s;
    transform: translateY(-200vh);
    overflow-y: scroll;
    z-index: 12;
    display: none;
    box-shadow: rgb(0 0 0 / 3%) 0px 16px 24px;
    &.slide {
        transform: translateX(0);
        transition: 400ms ease-in-out;
        display: block;
    }
`;
const Middle = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
`;
const Ul = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`;
const LI = styled.li`
    border-bottom: none;
    list-style: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 20px;
`;
const A = styled.a`
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
`;

