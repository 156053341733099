import React from 'react';
import Slider from 'react-slick';
import Styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const data = [
    {
        id: 1,
        name: "Yasir Muhammed",
        image: require("../../../assets/images/avatar.png"),
        description: "The stringent quality control measures implemented by Mplus Concrete are truly commendable. From raw material selection to the final product, every batch undergoes thorough testing, giving us confidence in the strength and durability of our structures.",
    },
    {
        id: 2,
        name: "Siya WIlson",
        image: require("../../../assets/images/avatar.png"),
        description: "Timely delivery is crucial in construction, and Mplus Concrete understands this well. Their optimized transportation and commitment to punctuality played a key role in ensuring our project was completed on schedule",
    },
    {
        id: 3,
        name: "John Doe",
        image: require("../../../assets/images/avatar.png"),
        description: "Mplus Concrete goes beyond just delivering concrete; their customer interaction is remarkable. They maintain open communication, address queries promptly, and provide regular updates, creating a collaborative and transparent construction experience.",
    },
    {
        id: 4,
        name: "Mahadev Rathore",
        image: require("../../../assets/images/avatar.png"),
        description: "Choosing Mplus Concrete was an investment in the longevity of our structures. Their premium quality concrete, coupled with rigorous quality control, has resulted in constructions that stand strong, showcasing the enduring impact of their materials",
    },
    {
        id: 4,
        name: "Gowri Sankar",
        image: require("../../../assets/images/avatar.png"),
        description: "Choosing Mplus Concrete for our concrete needs was a decision we never regretted. Their ready mix not only showcased exceptional quality but their commitment to timeliness and customer service was outstanding. The team's professionalism and attention to detail made our construction project a smooth and successful experience.",    },
];
export default function TestimonialSlider() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        // rows: 1,
        // slidesPerRow: 1,
        centerPadding: "60px",
        className: "center",
        responsive: [
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            
          ]
    };


    let renderItems = () => {
        return data.map((item) => (
            <List key={item.id}>
                <FlexContainer>
                    <ImageContainer><Image src={item.image} /></ImageContainer>
                    <DetailsContainer>
                        <BioContainer>
                            <LeftBio>
                                <Name>{item.name}</Name>
                            </LeftBio>
                        </BioContainer>
                        <FeedBackContainer>
                            <Comment>{item.description}</Comment>
                        </FeedBackContainer>
                    </DetailsContainer>
                </FlexContainer>
            </List>
        ))
    }

    return (
        <SliderContainer>
            <Slider {...settings}>
                {renderItems()}
            </Slider>
        </SliderContainer>
    );
}

const SliderContainer = Styled.div`
  margin: auto;
  overflow: hidden;
`;

const FlexContainer = Styled.div`
    width: 100%;
    margin-bottom: 0;
`;
const List = Styled.div`
    border: 1px solid #D9D9D9;
    background: #F4F4F4;
    padding: 50px;
    border-radius: 5px;
    min-height: 390px;
    margin: 0px 20px 20px;
    /* transition: all 0.3s ease 0s; */
    width: 90%!important;
`;
const ImageContainer = Styled.div`
    width: 88px;
    height: 88px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    margin : 0 auto;
`;
const Image = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;
const DetailsContainer = Styled.div`
`;
const BioContainer = Styled.div`

`;
const LeftBio = Styled.div`

`;
const Name = Styled.h4`
    font-size: 18px;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;   
    color: #292929;
`;

const FeedBackContainer = Styled.div`
    margin-top: 20px;
    `;
const Comment = Styled.p`
    color: #292929;
    text-align: justify;
    font-family: 'Karla', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.7rem;
`;


